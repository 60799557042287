<template id="goodsSeckill-body">
  <div class="goodsSeckill">
    <div class="g_timer">
      <span class="timeText" v-if="timeState == 0">尚未有秒杀活动</span>
      <span class="timeText" v-if="timeState == 1">距本场开始</span>
      <span class="timeText" v-if="timeState == 2">距本场结束</span>

      <div class="time_content">
        <span>{{ hr }}</span>
        <i>:</i>
        <span>{{ min }}</span>
        <i>:</i>
        <span>{{ sec }}</span>
      </div>
    </div>
    <div class="seckill-ul" v-if="timeState != 0">
      <div
        class="li"
        v-for="item in goods_list"
        :key="item.goods_id"
        @click="goProductPage(item.goods_id)"
      >
        <img :src="item.goodsimage_full_url" class="mainImage" />
        <div class="content">
          <h1 class="title">{{ item.goods_name }}</h1>
          <!-- <div class="progress">
            <div class="solid"></div>
            已抢10%
          </div> -->
          <div class="buy">
            <span class="price">¥ {{ item.xianshigoods_price }}</span>

            <b class="btn">立即抢购</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";
export default {
  data() {
    return {
      activity: null,
      goods_list: null,
      timeState: 0, //已结束或没活动  1待开始  2待结束
      time: null,
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      timer: null,
    };
  },

  created() {
    document.body.classList.add("goodsSeckill-body");
    this.getList();
  },
  beforeDestroy() {
    document.body.classList.remove("goodsSeckill-body");
  },
  methods: {
    countdown: function() {
      const end = this.time * 1000;
      console.log(this.time);
      // const end = Date.parse(new Date('2020-12-03 00:00:00'))
      // console.log(end,this.times*1000)
      const now = Date.parse(new Date());
      const msec = end - now;
      // if (!this.times || msec >) {
      //         return false;
      //       }
      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);

      this.day = day;
      let _hr = day * 24 + hr;
      this.hr = _hr > 9 ? _hr : "0" + _hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      const that = this;
      this.timer = setTimeout(function() {
        if (msec <= 0) {
          clearTimeout(this.timer);
        } else {
          that.countdown();
        }
      }, 1000);
    },
    goProductPage(id) {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: { goods_id: id },
      });
    },
    finish() {
      let { xianshi_starttime, xianshi_end_time } = this.activit;
      let nowTime = parseInt(new Date().getTime() / 1000);

      if (xianshi_starttime > nowTime) {
        //待开始
        this.timeState = 1;
        this.time = xianshi_starttime;
        this.countdown();
      } else if (xianshi_end_time > nowTime) {
        //待结束
        this.timeState = 2;
        this.time = xianshi_end_time;
        this.countdown();
      } else {
        this.timeState = 0;
      }
    },
    async getList() {
      let data = await post("/v3.MemberGoods/getFlashSaleGoodsList");
      this.activit = data.activity;
      this.goods_list = data.goods_list;
      this.finish();
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.goodsSeckill {
  width: rem(750);
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
  padding: rem(20) rem(24);
  background: linear-gradient(
    180deg,
    #ff4848 39.06%,
    rgba(255, 255, 255, 0) 100%
  );

  .g_timer {
    background: #ffffff;
    border-radius: rem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(56);
    margin: rem(20) 0;
    .timeText {
      font-weight: 400;
      font-size: rem(24);
      line-height: rem(34);
      color: #000000;
      margin-right: rem(20);
    }
    .time_content {
      display: flex;
      span {
        // width: rem(37);
        padding: 0 rem(4);
        box-sizing: border-box;
        height: rem(36);
        background: #4e4e4e;
        border-radius: rem(8);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(34);
        color: #ffffff;
      }
      i {
        color: #666666;
        font-weight: 400;
        margin: 0 rem(8);
      }
    }
  }

  .seckill-ul {
    box-sizing: border-box;
    padding: 0 rem(24) rem(24);
    background: #ffffff;
    border-radius: rem(16);

    .li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: rem(24);
      box-sizing: border-box;
    }
    .mainImage {
      width: rem(230);
      height: rem(230);
      border-radius: rem(8);
      margin-right: rem(16);
    }
    .content {
      // display: flex;
      .title {
        color: #222222;
        font-weight: 600;
        font-size: rem(30);
        line-height: rem(42);
        max-height: rem(84);
        overflow: hidden;
        margin-bottom: rem(8);
      }
      .progress {
        background: #f9f9fa;
        border-radius: rem(14);
        width: rem(250);
        height: rem(28);
        line-height: rem(28);
        font-weight: 400;
        font-size: rem(18);
        color: #ff4848;
        padding-left: rem(20);
        margin-bottom: rem(30);
        position: relative;
        .solid {
          border-radius: rem(14);
          width: 50%;
          height: rem(28);
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(255, 72, 72, 0.1);
        }
      }
      .buy {
        margin-top: rem(30);
        width: rem(408);
        height: rem(80);
        border-radius: rem(8);
        display: flex;
        align-items: center;
        position: relative;
        background: url("https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050710541131957.png")
          no-repeat;
        background-size: 100%;
        .price {
          font-family: "price_bold" !important;
          width: 45%;
          color: #ffffff;
          font-weight: 700;
          font-size: rem(30);
          margin-left: rem(24);
          display: block;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .btn {
          width: 50%;
          font-weight: 400;
          font-size: rem(28);
          line-height: rem(39);
          display: block;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rem(28);
          color: #ff4848;
        }
      }
    }
  }
}
</style>
